<template>
  <div class="strategy-page">
    <van-nav-bar title="售后服务政策" left-arrow  @click-left="$router.go(-1)"/>
    <div class="strategy">
      <div class="part-a">
        <h2>服务承诺</h2>
        <img :src="imgs.s1" alt="">
      </div>
      <div class="part-b">
        <h2>产品售后政策查询</h2>
        <div class="items">
          <a href="/services/band" class="item">
            <img :src="imgs.s2"  alt="">
          </a>
          <a href="/services/watch" class="item">
            <img :src="imgs.s3"  alt="">
          </a>
          <a href="/services/earphone" class="item">
            <img :src="imgs.s4"  alt="">
          </a>
          <a href="/services/treadmill" class="item ts">
            <img :src="imgs.s5" alt="">
          </a>
          <a href="/services/sport" class="item">
            <img :src="imgs.s6"  alt="">
          </a>
        </div>
      </div>
      <div class="part-c">
        <div class="panel">
          <h2>邮寄须知</h2>
          <p>
            1、在三包有效期内，请用户先行支付运费将产品快递至Amazfit售后指定地址，产品经售后工程师检测后，如具有性能故障，运费将按照邮寄区域予以补偿（根据不同区域最高每单10元或15元，详细请见下表），如无性能故障，Amazfit售后会将产品按原地址寄回给用户，运费不再予以补偿。</p>
            <table class="regions">
              <thead>
                <tr>
                    <th>各区域</th>
                    <th class="charge">运费补偿</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>上海市,江苏省,浙江省,安徽省</td>
                    <td rowspan="3" class="charge">10元</td>
                  </tr>
                  <tr>
                    <td>福建省,江西省,河南省,湖北省,湖南省</td>
                  </tr>
                  <tr>
                    <td>广东省,广西壮族自治区,海南省</td>
                  </tr>
                  <tr>
                    <td>贵州省,云南省,重庆市,四川省</td>
                    <td rowspan="3" class="charge">15元</td>
                  </tr>
                  <tr>
                    <td>黑龙江省,吉林省,辽宁省</td>
                  </tr>
                  <tr>
                    <td>北京市,天津市,山西省,河北省,山东省,陕西省</td>
                  </tr>
                  <tr>
                    <td>甘肃省,青海省,宁夏回族自治区</td>
                    <td rowspan="2" class="charge">18元</td>
                  </tr>
                  <tr>
                    <td>西藏自治区,内蒙古自治区,新疆维吾尔自治区</td>
                  </tr>
              </tbody>
            </table>
            <br/>
            <p>2、 保外维修，则运费谁寄谁付，用户承担寄回Amazfit售后的费用，Amazfit售后承担寄还用户的费用。<br>3、 Amazfit售后不支持到付和平邮方式。<br>
            4、 用户申请售后服务时，请务必提前备份个人数据。维修过程中可能会造成个人数据丢失，Amazfit售后不承担维修过程中个人数据损坏、丢失责任。
            <br>
            5、 Amazfit售后暂不提供跨境邮寄的服务。如果购买产品后在中国大陆以外的地区使用，并且需要寄回中国大陆进行售后处理时，境外部分的运费自行承担，且需提供一个有效的大陆境内的收货地址，以便Amazfit回寄产品。大陆境内寄到Amazfit售后运费承担，请参考上述第1、2、3条的规定。
            <br />
            6、 跑步机产品，提供上门维修服务，您可以联系微信公众号右下角-我的服务-在线客服获取指导。
            </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CDN_URL } from '../../config'
export default {
  name: 'strategy',
  data () {
    return {
      imgs: {
        s1: CDN_URL + '/images/service/strategy/s1.png',
        s2: CDN_URL + '/images/service/strategy/s2.png',
        s3: CDN_URL + '/images/service/strategy/s3.png',
        s4: CDN_URL + '/images/service/strategy/s4.png',
        s5: CDN_URL + '/images/service/strategy/s5.png',
        s6: CDN_URL + '/images/service/strategy/s6.png'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.strategy {
  h2 {
    margin: 0;
    font-size: 6.4vw;
    color: #000;
    margin-bottom: 10vw;
    text-align: center;
  }
  p {
    margin: 0;
  }
  .part-a {
    text-align: center;
    padding: 10vw 0 15vw;
    img {
      width: 72vw;
    }
  }
  .part-b {
    background: #F2F2F2;
    padding: 8vw 0;
    h2 {
      text-align: center;
    }
    .items {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 4vw;
      .item {
        width: 41vw;
        margin: 2vw;
      }
    }
  }
}
.part-c {
  padding-top: 5vw;
}
.panel {
  padding: 10px 20px;
  background-color: #FFF;
  color: #666;
  margin-bottom: 20px;

  p {
    margin-bottom: 10px;
    line-height: 200%;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: #F8F8F8;
    }

    td, th {
      padding: 10px;
      text-align: left;
      border: 1px solid #000;
      width: 50%;
    }
  }
}

.regions {
  tr {
    td, th {
      padding: 10px;
      text-align: center;
    }
  }

  .region {
    width: 15%;
  }

  .charge {
    width: 20%;
  }
}
</style>
